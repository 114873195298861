var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" Leave of absence request history - "+_vm._s(_vm.teacherName)+" ")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.requestAbsence()}}},[_vm._v("Request a Leave of Absence")])],1),_c('div',{staticClass:"success"},[_c('v-alert',{staticStyle:{"font-size":"14px","padding":"12px"},attrs:{"value":_vm.checkSuccess,"transition":"scale-transition","type":"success"}},[_vm._v(" Update absence successfully ")])],1),_c('div',{staticClass:"pb-5"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.leaveOfAbsence,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","fixed-header":"","height":"65vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.reason)+" ")]}},{key:"item.until",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.until))]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.from))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'PENDING')?_c('v-chip',{attrs:{"text-color":"blue","small":""}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'REJECTED')?_c('v-chip',{attrs:{"text-color":"error","small":""}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'APPROVED')?_c('v-chip',{attrs:{"text-color":"success","small":""}},[_vm._v(_vm._s(item.status))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.status === 'PENDING')?_c('i',{staticClass:"isax isax-edit-2 fs-18 font-weight-bold mr-4",on:{"click":function($event){return _vm.edit(item.recordID)}}}):_vm._e(),(item.status === 'PENDING')?_c('v-icon',{attrs:{"size":"18","color":"primary"},on:{"click":function($event){_vm.$refs.confirmDelete.open();
                    _vm.recordID = item.recordID;}}},[_vm._v("close")]):_vm._e()],1)]}}],null,true)}):_vm._e()],1)])])],1),(!_vm.isLoading)?_c('v-footer',{staticClass:"pl-10 pr-10",attrs:{"fixed":"","absolute":"","height":"60","color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e(),_c('popup-confirm',{ref:"confirmDelete",attrs:{"maxWidth":_vm.maxWidth,"width":_vm.width,"confirmColor":"error"},on:{"confirm":_vm.confirmDelete,"cancel":_vm.cancelDelete}},[_c('v-card-title',{staticClass:"pt-0",staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"slot":"icon"},slot:"icon"},[_c('v-icon',{staticClass:"mb-5",attrs:{"x-large":"","color":"error","outline":""}},[_vm._v("mdi-close-circle")])],1),_c('v-card-title',{staticClass:"pt-0 mb-5",staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"slot":"title"},slot:"title"},[_c('h2',{staticClass:"text-center"},[_vm._v("Confirm cancel")]),_c('p',{staticClass:"text-center"},[_vm._v("Are you sure to cancel request?")])]),_c('v-card-actions')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }